import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Button, Chip, Stack, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
export default function LinearBuffer() {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1f1f1f",
        zIndex: 1300, // High z-index to ensure it overlays other components
      }}>
      <Box sx={{ width: "80%" }}>
        <Typography
          sx={{ fontSize: "20px", fontWeight: "bold", marginBottom: "5px" }}>
          Please wait we are processing the request ......
        </Typography>
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
        />
      </Box>
    </Box>
  );
}

export const BulkLoader = ({ progress, setLoadingScreen }) => {
  const estimatedTimeRemaining = (
    (progress.total - progress.completed) *
    0.5
  ).toFixed(1); // time in seconds
  const percentComplete = ((progress.completed / progress.total) * 100).toFixed(
    1
  );

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        placeContent: "center",
        backgroundColor: "#1f1f1f",
        zIndex: 1300,
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}>
        <Box sx={{ width: "80%" }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "5px",
              }}>
              Processing {progress.completed ?? 0} of {progress.total ?? 0}{" "}
              orders...
            </Typography>
            <Typography sx={{ fontSize: "16px", marginBottom: "5px" }}>
              Estimated time remaining: {estimatedTimeRemaining} seconds
            </Typography>
            <Stack direction="row" spacing={2}>
              <Chip
                label={`Success: ${progress.completed}`}
                color="success"
                size="medium"
                icon={<DoneIcon />}
              />
              <Chip
                label={`Failed: ${progress.failed}`}
                color="error"
                size="medium"
                icon={<CloseIcon />}
              />
            </Stack>
          </Stack>
          <LinearProgress variant="determinate" value={percentComplete} />
          <Typography sx={{ fontSize: "16px", marginTop: "10px" }}>
            {percentComplete}% completed
          </Typography>
        </Box>
        <Button
          sx={{ marginLeft: "10px" }}
          variant="contained"
          color="warning"
          onClick={() => setLoadingScreen(false)}>
          Close
        </Button>
      </Box>
    </Box>
  );
};
