import axios from "axios";
import { XMLParser } from "fast-xml-parser";

export function parseTxtLine(line, courier) {
  const values = line.split("\t");
  if (courier === "usps") {
    return {
      To: {
        Weight: 0,
        ToCountry: values[23],
        ToState: values[21],
        ToName: values[16],
        ToCompany: "",
        ToStreet1: values[17],
        ToStreet2: values[18],
        ToCity: values[20],
        ToZip: values[22],
        ToPhone: values[9].split("ext.")[0],
        productName: values[11],
        quantity: values[12],
        reference1: "",
      },
    };
  } else {
    return {
      To: {
        Weight: 0,
        Length: 1,
        Width: 1,
        Height: 1,
        ToCountry: values[23],
        ToState: values[21],
        ToName: values[16],
        ToCompany: "",
        ToStreet1: values[17],
        ToStreet2: values[18],
        ToCity: values[20],
        ToZip: values[22],
        ToPhone: values[9].split("ext.")[0],
        productName: values[11],
        quantity: values[12],
        reference1: "",
      },
    };
  }
}

export async function parseCSVLine(row, courier) {
  let toState = row.ToState;
  let zipCode = row.ToZip;

  if (zipCode && zipCode.toString().length > 5) {
    zipCode = parseInt(zipCode.toString().substring(0, 5), 10);
  }
  console.log(toState);
  if (toState === "" && row.ToState.length !== 2) {
    const zip = await getDeliveryInformation(zipCode);
    if (zip.status !== "success") {
      return "notFound";
    }
    toState = zip.info.deliveryState;
  }

  if (courier === "usps") {
    return {
      To: {
        Weight: parseFloat(row.Weight),
        ToName: row.ToName,
        ToCompany: "",
        ToStreet1: row.ToStreet,
        ToStreet2: row.ToStreet2,
        ToCity: row.ToCity,
        ToState: toState,
        ToZip: row.ToZip,
        ToCountry: "US",
        ToPhone: "",
        reference1: "",
      },
      From: {
        FromName: row.FromName,
        FromStreet1: row.FromStreet,
        FromStreet2: row.FromStreet2,
        FromCity: row.FromCity,
        FromState: row.FromState,
        FromZip: row.FromZip,
        FromCountry: "US",
        FromCompany: "",
      },
    };
  } else {
    return {
      To: {
        Length: 1,
        Width: 1,
        Height: 1,
        Weight: parseFloat(row.Weight),
        ToName: row.ToName,
        ToCompany: "",
        ToStreet1: row.ToStreet,
        ToStreet2: row.ToStreet2,
        ToCity: row.ToCity,
        ToState: toState,
        ToZip: row.ToZip,
        ToCountry: "US",
        ToPhone: "",
        reference1: "",
      },
      From: {
        FromName: row.FromName,
        FromStreet1: row.FromStreet,
        FromStreet2: row.FromStreet2,
        FromCity: row.FromCity,
        FromState: row.FromState,
        FromZip: row.FromZip,
        FromCountry: "US",
        FromCompany: "",
      },
    };
  }
}
async function getDeliveryInformation(zip) {
  const xmlBodyStr = `
    <CityStateLookupRequest  USERID="304LOPRO6405">
     <ZipCode ID='0'>
     <Zip5>${zip}</Zip5>
     </ZipCode>
    </CityStateLookupRequest>
  `;

  try {
    const response = await axios.get(
      `https://production.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=${encodeURIComponent(
        xmlBodyStr
      )}`
    );
    const parser = new XMLParser();
    const parsedData = await parser.parse(response.data);
    const deliveryState =
      parsedData?.CityStateLookupResponse?.ZipCode?.[0].State?.[0];
    const deliveryCity =
      parsedData?.CityStateLookupResponse?.ZipCode?.[0].City?.[0];
    const info = {
      deliveryCity: deliveryCity,
      deliveryState: deliveryState,
    };
    return { status: "success", info };
  } catch (error) {
    console.log(error);
    return { status: "failed", value: error };
  }
}
