import {
  alpha,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import PageContainer from "../components/containers/PageContainer";
import CustomSelect from "../components/ui/CustomSelect";
import CustomTable from "../components/ui/CustomTable";
import Section from "../components/ui/Section";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import OptionCard from "../components/common/OptionCard";
import api from "../config/axios";
import { toast } from "react-toastify";
import env from "../config/env";
import Loader from "../components/ui/Loader";
import { formatDate, states } from "../utilities/misc";
import { useUserContext } from "../App";
import Field from "../components/ui/Field";
import ArticleIcon from "@mui/icons-material/Article";
import { BulkLoader } from "../components/common/OrderLoader";
import StatusComp from "../components/common/StatusComp";

import { parseCSVLine, parseTxtLine } from "../utilities/BulkordersUtils";
const pricingItems = [
  { name: "usps", icon: "courier-icon-01" },
  { name: "ups", icon: "courier-icon-03" },
  { name: "fedex", icon: "courier-icon-02" },
];

const ASCsvOrders = () => {
  const [selectedCourier, setSelectedCourier] = useState(pricingItems[0].name);
  const [loader, setLoader] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [csvpaydisabled, setCsvpaydisabled] = useState(true);
  const [csvPrice, setCsvPrice] = useState(0);
  const [validateLoader, setValidateLoader] = useState(false);
  const [CSV, setCSV] = useState("");
  const [types, setTypes] = useState([]);
  const [activeUspsType, setActiveUspsType] = useState({});
  const [Weight, setWeight] = useState(0);
  const [csvOrders, setCsvOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const theme = useTheme();
  const [showConfirm, setShowConfirm] = useState(false);
  const [orderDATA, setOrderDATA] = useState([]);
  const [fileType, setFileType] = useState("");
  const [csvOrderID, setCsvOrderID] = useState("");
  const [From, setFrom] = useState({
    name: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    country: "US",
  });
  useEffect(() => {
    readLabelTypes();
  }, []);

  useEffect(() => {
    readCsvOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const readLabelTypes = async () => {
    await api
      .get("/labeltype/read")
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateFile = async (e) => {
    e.preventDefault();
    console.log(CSV);

    console.log("File Type:", fileType);
    if (!activeUspsType) {
      toast.error("Please select a label type");
      return;
    }

    setValidateLoader(true);
    try {
      const file = CSV;
      const fileType = file.name.split(".").pop().toLowerCase(); // Extract file extension
      const fileContent = await file.text();

      let parsedData = [];

      if (fileType === "txt") {
        // Parse .txt file
        const lines = fileContent.split("\n").slice(1, -1); // Remove header and footer
        const promises = lines.map((line) =>
          parseTxtLine(line, selectedCourier)
        );
        parsedData = await Promise.all(promises);
      } else if (fileType === "csv") {
        // Parse .csv file
        const rows = fileContent.split("\n").filter((row) => row.trim() !== "");

        const promises = rows.map((row) => {
          const values = row.split(",");
          const rowData = {
            Weight: values[0]?.trim(),
            FromName: values[1]?.trim(),
            FromStreet: values[2]?.trim(),
            FromStreet2: values[3]?.trim(),
            FromCity: values[4]?.trim(),
            FromState: values[5]?.trim(),
            FromZip: values[6]?.trim(),
            ToName: values[7]?.trim(),
            ToStreet: values[8]?.trim(),
            ToStreet2: values[9]?.trim(),
            ToCity: values[10]?.trim(),
            ToState: values[11]?.trim(),
            ToZip: values[12]?.trim(),
          };
          return parseCSVLine(rowData, selectedCourier);
        });
        parsedData = await Promise.all(promises);
      }
      console.log(parsedData);
      if (parsedData.length === 0) {
        toast.error("The uploaded CSV file is empty or invalid.");
        return;
      }

      const transformedData = {
        From: parsedData[0].From || {},
        To: parsedData.map((item) => item.To),
      };

      setFrom({
        name: transformedData?.From?.FromName,
        street: transformedData?.From?.FromStreet1,
        street2: transformedData?.From?.FromStreet2,
        city: transformedData?.From?.FromCity,
        state: transformedData?.From?.FromState,
        zip: transformedData?.From?.FromZip,
        phone: "",
        country: "US",
      });
      setOrderDATA(transformedData.To);
      toast.success("File validated successfully");
      setShowConfirm(true);
    } catch (err) {
      console.log(err);
      toast.error(
        err.response?.data?.message ||
          "An error occurred while processing the file."
      );
    } finally {
      setValidateLoader(false);
    }
  };

  const getPricing = async (e) => {
    e.preventDefault();

    if (!activeUspsType) {
      toast.error("Please select a label type");
      return;
    }

    setValidateLoader(true);

    const data = {
      type: activeUspsType._id,
      json: orderDATA,
    };
    await api
      .post("/as-csv/getPrice", data)
      .then((res) => {
        setValidateLoader(false);
        setCsvPrice(res.data.totalPrice);
        setOrderDATA(res.data.verifiedOrders);
        setCsvpaydisabled(false);
        toast.success("Pricing got successfully");
      })
      .catch((err) => {
        setValidateLoader(false);
        toast.error(err.response.data.message);
      });
  };
  const { refresh } = useUserContext();
  const [progress, setProgress] = useState({
    total: 0,
    completed: 0,
    failed: 0,
  });
  const checkJobStatus = async (jobId) => {
    try {
      const response = await api.get(`/job/status/${jobId}`);
      setProgress({
        total: response.data.total,
        completed: response.data.completed,
        failed: response.data.failed,
      });
      setLoadingScreen(true);
      if (response.data.status === "completed") {
        // Handle success
        toast.success("Orders created successfully");
        downloadZip(csvOrderID);
        refresh();
        setCsvpaydisabled(false);
        setCSV("");
        setWeight(0);
        setCsvPrice(0);
        setLoader(false);
        setLoadingScreen(false);
        readCsvOrders();
        setShowConfirm(false);
        setCsvOrderID("");
      } else if (response.data.status === "failed") {
        // Handle failed
        toast.error("Something went wrong with the order's all order's failed");
        readCsvOrders();
        setCsvpaydisabled(false);
        setCSV("");
        setWeight(0);
        setCsvPrice(0);
        setLoader(false);
        setLoadingScreen(false);
        setShowConfirm(false);
        setCsvOrderID("");
      } else {
        // Continue polling
        setTimeout(() => checkJobStatus(jobId), 1000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoader(false);
      setCsvpaydisabled(false);
    }
  };

  const createOrderFromCSV = async (e) => {
    e.preventDefault();
    if (
      From.name === "" ||
      From.phone === "" ||
      From.street === "" ||
      From.city === "" ||
      From.state === "" ||
      From.zip === ""
    ) {
      toast.error("Please fill all the required fields");
      setLoader(false);
      return;
    }

    const data = {
      type: activeUspsType._id,
      json: orderDATA,
      total_price: csvPrice,
      From: {
        FromName: From.name,
        FromPhone: From.phone,
        FromStreet1: From.street,
        FromStreet2: From.street2,
        FromCity: From.city,
        FromState: From.state,
        FromZip: From.zip,
        FromCountry: From.country,
      },
    };

    setCsvpaydisabled(true);
    setLoader(true);

    try {
      const response = await api.post("/as-csv/create", data);
      const jobId = response.data.jobId;
      checkJobStatus(jobId);
      setCsvOrderID(response.data.orderId);
      toast.success("Request received, please wait we are processing...");
    } catch (error) {
      toast.error(error.response.data.message);
      setLoader(false);
      setCsvpaydisabled(false);
    }
  };

  const readCsvOrders = async () => {
    setOrdersLoading(true);
    await api
      .get(`/as-csv/read?page=${page}&limit=10`)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setCsvOrders(res.data.ASCsvOrders);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setOrdersLoading(false));
  };

  const downloadZip = async (id) => {
    await api
      .get(`/as-csv/download/${id}`, env.downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  //for phone number auto-generation
  const generatePhoneNo = () => {
    const num = Math.floor(9000000000 + Math.random() * 1000000000);
    setFrom({
      ...From,
      phone: num.toString(),
    });
  };

  const downloadCsv = async (id) => {
    await api
      .get(`/as-csv/download-csv/${id}`, env.downloadConfig)
      .then((response) => {
        //  download zip file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "csvtrackings.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const downloadFailedCsv = async (failedLabelsCsv) => {
    await api
      .get(`${env.BASE_API_URL}/${failedLabelsCsv}`)
      .then((response) => {
        //  download zip file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "failedOrders.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <PageContainer
      title="Amazon Orders"
      desc="Place orders in bulk using Amazon File"
      end={
        <a href="/assets/sample_amazon.csv" download>
          <Button variant="contained">Download Sample CSV</Button>
        </a>
      }>
      {loader && loadingScreen ? (
        <BulkLoader progress={progress} setLoadingScreen={setLoadingScreen} />
      ) : (
        ""
      )}
      {!showConfirm ? (
        <>
          <Section sx={{ mb: 4 }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              gap={1}
              alignItems={{ xs: "stretch", sm: "center" }}
              mb={2}>
              <FormLabel style={{ flex: "none", fontWeight: 600 }}>
                Select Courier
              </FormLabel>
              <Grid container gap={2}>
                {pricingItems.map((p) => (
                  <OptionCard
                    imgSrc={
                      "/assets/images/" +
                      p.name +
                      (theme.palette.mode === "dark" ? "2" : "") +
                      ".svg"
                    }
                    name={p.name}
                    active={selectedCourier === p.name}
                    activate={() => setSelectedCourier(p.name)}
                  />
                ))}
              </Grid>
            </Stack>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} sm={7}>
                <CustomSelect
                  label="Select Service"
                  name="labeltype"
                  onChange={(e) => {
                    setActiveUspsType(
                      types.find((type) => type._id === e.target.value)
                    );
                  }}
                  options={types
                    .filter((type) =>
                      type.name.toLowerCase().includes(selectedCourier)
                    )
                    .map((type) => ({
                      label: type.name,
                      value: type._id,
                    }))}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl sx={{ height: "100%" }} fullWidth>
                  <FormLabel sx={{ fontWeight: 600, mb: 0.6 }}>
                    Upload File
                  </FormLabel>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{
                      height: "100%",
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      borderStyle: "dashed",
                    }}>
                    {CSV?.name || "+ Upload File (.txt/.csv)"}

                    <input
                      accept=".txt, .csv"
                      type="file"
                      id="csv"
                      hidden
                      name="csv"
                      required
                      onChange={(e) => {
                        setCSV(e.target.files[0]);
                      }}
                    />
                  </Button>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  disabled={
                    validateLoader ||
                    CSV === "" ||
                    Object.keys(activeUspsType).length === 0
                  }
                  onClick={validateFile}
                  variant="outlined"
                  color="success"
                  sx={{
                    width: "100%",
                    mr: 2,
                    mt: 3,
                    bgcolor: alpha(theme.palette.success.main, 0.1),
                    fontWeight: 600,
                  }}>
                  {validateLoader ? <Loader /> : "Validate File"}
                </Button>
              </Grid>
            </Grid>
          </Section>

          <CustomTable
            fields={[
              "ORDER ID ",
              "ORDER DATE",
              "NO. OF ORDERS",
              "LABEL TYPE",
              "PRICE",
              "STATUS",
              "ACTION",
            ]}
            loading={ordersLoading}
            count={totalPages}
            page={page}
            pagination
            setPage={setPage}>
            {csvOrders?.map((order) => (
              <TableRow>
                <TableCell>{order._id}</TableCell>
                <TableCell>{formatDate(order.createdAt)}</TableCell>
                <TableCell>{order.orders?.length}</TableCell>
                <TableCell>{order.labelType?.name}</TableCell>
                <TableCell>${Number(order.price).toFixed(2)}</TableCell>
                <TableCell>
                  <StatusComp status={order?.status} />
                </TableCell>
                <TableCell>
                  <IconButton
                    color="warning"
                    onClick={() => {
                      downloadZip(order._id);
                    }}>
                    <DownloadRoundedIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="info"
                    title="Download Trackings"
                    onClick={() => {
                      downloadCsv(order._id);
                    }}>
                    <ArticleIcon fontSize="small" />
                  </IconButton>

                  {order.hasFailedOrders && (
                    <IconButton
                      color="error"
                      title="Download Failed Orders CSV"
                      onClick={() => {
                        downloadFailedCsv(order.failedLabelsCsv);
                      }}>
                      <ArticleIcon fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </CustomTable>
        </>
      ) : (
        <>
          {fileType === "txt" ? (
            <Section sx={{ mb: 4 }}>
              <Section title={"Sender’s Details"} mb0 sx={{ mb: 2 }}>
                <Grid container mt={2} spacing={2}>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={6}>
                      <Stack spacing={2}>
                        <CustomSelect
                          label="Country*"
                          disabled
                          required
                          name={"fromCountry"}
                          value={
                            activeUspsType?.uid === "ups_standard" ||
                            activeUspsType?.uid === "ups_express" ||
                            activeUspsType?.uid === "ups_express_saver" ||
                            activeUspsType?.uid === "ups_express_early" ||
                            activeUspsType?.uid === "ups_expedited"
                              ? "CA"
                              : "US"
                          }
                          options={[
                            {
                              label: "Canada",
                              value: "CA",
                            },
                            {
                              label: "United States",
                              value: "US",
                            },
                          ]}
                        />

                        <Field
                          label="Address 1*"
                          placeholder="Enter Address 1"
                          value={From.street}
                          onChange={(e) =>
                            setFrom({
                              ...From,
                              street: e.target.value,
                            })
                          }
                          required
                        />

                        <Field
                          label="City*"
                          placeholder="Enter City"
                          required
                          value={From.city}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              city: e.target.value,
                            });
                          }}
                        />
                        <Field
                          label="Zip*"
                          placeholder="Enter Zipcode"
                          required
                          value={From.zip}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              zip: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={2}>
                        <Field
                          label="Contact Name*"
                          placeholder="Enter contact name"
                          value={From.name}
                          onChange={(e) =>
                            setFrom({ ...From, name: e.target.value })
                          }
                          required
                        />
                        <Field
                          label="Address 2 (Optional)"
                          placeholder="Enter Address 2"
                          value={From.street2}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              street2: e.target.value,
                            });
                          }}
                        />

                        <Field
                          label={
                            <Box sx={{ mb: -0.2 }}>
                              Phone*
                              <Button
                                sx={{ p: 0, ml: 1 }}
                                type="button"
                                onClick={generatePhoneNo}>
                                Auto-generate
                              </Button>
                            </Box>
                          }
                          placeholder="Enter phone number"
                          value={From.phone}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              phone: e.target.value,
                            });
                          }}
                        />

                        <CustomSelect
                          label="State*"
                          value={From?.state || ""}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              state: e.target.value,
                            });
                          }}
                          options={states
                            .concat(
                              selectedCourier === "usps"
                                ? [
                                    {
                                      Country: "US",
                                      ID: "VI",
                                      Name: "U.S. Virgin Islands",
                                    },
                                    {
                                      Country: "US",
                                      ID: "GU",
                                      Name: "Guam",
                                    },
                                  ]
                                : []
                            )
                            .filter((state) => state.Country === "US")
                            .map((state) => ({
                              label: state.Name,
                              value: state.ID,
                            }))}
                          required
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTable
                        fields={[
                          "Receiver Name ",
                          "Address",
                          "Purchased Items",
                          "Quantity",
                          "Reference",
                          "Weight",
                        ]}>
                        {orderDATA?.map((order, index) => (
                          <TableRow key={order.ToName}>
                            <TableCell>{order.ToName}</TableCell>
                            <TableCell>
                              {order.ToName} <br />
                              {order.ToPhone} <br />
                              {order.ToStreet1} , {order.ToStreet2} <br />
                              {order.ToCity} , {order.ToState} , {order.ToZip}{" "}
                              <br />
                              {order.ToCountry}
                            </TableCell>

                            <TableCell>{order.productName}</TableCell>
                            <TableCell>{order.quantity}</TableCell>
                            <TableCell>
                              <Field
                                label=""
                                multiline
                                rows={2}
                                placeholder="Enter Reference(optional)"
                                value={orderDATA[index]?.reference1 || ""}
                                onChange={(e) =>
                                  setOrderDATA((prevOrderDATA) => {
                                    const newData = [...prevOrderDATA];
                                    newData[index] = {
                                      ...newData[index],
                                      reference1: e.target.value,
                                    };
                                    return newData;
                                  })
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                label="Weight*"
                                placeholder="Enter Weight"
                                value={orderDATA[index]?.Weight || ""}
                                onChange={(e) =>
                                  setOrderDATA((prevOrderDATA) => {
                                    const newData = [...prevOrderDATA];
                                    newData[index] = {
                                      ...newData[index],
                                      Weight: e.target.value,
                                    };
                                    return newData;
                                  })
                                }
                                required
                              />
                              <br />
                              {selectedCourier !== "usps" && (
                                <Stack direction="row" spacing={1}>
                                  <Field
                                    label="Height*"
                                    placeholder="Enter Height"
                                    value={orderDATA[index]?.Height || ""}
                                    onChange={(e) =>
                                      setOrderDATA((prevOrderDATA) => {
                                        const newData = [...prevOrderDATA];
                                        newData[index] = {
                                          ...newData[index],
                                          Height: e.target.value,
                                        };
                                        return newData;
                                      })
                                    }
                                    required
                                  />
                                  <Field
                                    label="Width*"
                                    placeholder="Enter Width"
                                    value={orderDATA[index]?.Width || ""}
                                    onChange={(e) =>
                                      setOrderDATA((prevOrderDATA) => {
                                        const newData = [...prevOrderDATA];
                                        newData[index] = {
                                          ...newData[index],
                                          Width: e.target.value,
                                        };
                                        return newData;
                                      })
                                    }
                                    required
                                  />
                                  <Field
                                    label="Length*"
                                    placeholder="Enter Length"
                                    value={orderDATA[index]?.Length || ""}
                                    onChange={(e) =>
                                      setOrderDATA((prevOrderDATA) => {
                                        const newData = [...prevOrderDATA];
                                        newData[index] = {
                                          ...newData[index],
                                          Length: e.target.value,
                                        };
                                        return newData;
                                      })
                                    }
                                    required
                                  />
                                </Stack>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </CustomTable>
                    </Grid>
                  </Grid>
                </Grid>
              </Section>
              <Divider />
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                mt={2}
                sx={{ fontWeight: 500 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    disabled={validateLoader}
                    onClick={getPricing}
                    variant="outlined"
                    color="success"
                    sx={{
                      mr: 2,
                      flex: "none",
                      bgcolor: alpha(theme.palette.success.main, 0.1),
                      fontWeight: 600,
                    }}>
                    {validateLoader ? <Loader /> : "Get Pricing"}
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}>
                  <span style={{ color: "silver" }}>Your Total :</span>
                  <Typography color="primary" fontWeight={600}>
                    {typeof csvPrice === "number" ? (
                      <>${csvPrice?.toFixed(2)}</>
                    ) : (
                      "N/A"
                    )}
                  </Typography>
                  <Button
                    disabled={csvpaydisabled || typeof csvPrice !== "number"}
                    onClick={createOrderFromCSV}
                    variant="contained"
                    sx={{ ml: 1, px: 3 }}>
                    {loader ? <Loader /> : "Create Order"}
                  </Button>
                </div>
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="end"
                alignItems="center"
                spacing={1}
                mt={2}></Stack>
            </Section>
          ) : (
            <Section sx={{ mb: 4 }}>
              <Section title={"Sender’s Details"} mb0 sx={{ mb: 2 }}>
                <Grid container mt={2} spacing={2}>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={6}>
                      <Stack spacing={2}>
                        <CustomSelect
                          label="Country*"
                          disabled
                          required
                          name={"fromCountry"}
                          value={
                            activeUspsType?.uid === "ups_standard" ||
                            activeUspsType?.uid === "ups_express" ||
                            activeUspsType?.uid === "ups_express_saver" ||
                            activeUspsType?.uid === "ups_express_early" ||
                            activeUspsType?.uid === "ups_expedited"
                              ? "CA"
                              : "US"
                          }
                          options={[
                            {
                              label: "Canada",
                              value: "CA",
                            },
                            {
                              label: "United States",
                              value: "US",
                            },
                          ]}
                        />

                        <Field
                          label="Address 1*"
                          placeholder="Enter Address 1"
                          value={From.street}
                          onChange={(e) =>
                            setFrom({
                              ...From,
                              street: e.target.value,
                            })
                          }
                          required
                        />

                        <Field
                          label="City*"
                          placeholder="Enter City"
                          required
                          value={From.city}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              city: e.target.value,
                            });
                          }}
                        />
                        <Field
                          label="Zip*"
                          placeholder="Enter Zipcode"
                          required
                          value={From.zip}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              zip: e.target.value,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={2}>
                        <Field
                          label="Contact Name*"
                          placeholder="Enter contact name"
                          value={From.name}
                          onChange={(e) =>
                            setFrom({ ...From, name: e.target.value })
                          }
                          required
                        />
                        <Field
                          label="Address 2 (Optional)"
                          placeholder="Enter Address 2"
                          value={From.street2}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              street2: e.target.value,
                            });
                          }}
                        />

                        <Field
                          label={
                            <Box sx={{ mb: -0.2 }}>
                              Phone*
                              <Button
                                sx={{ p: 0, ml: 1 }}
                                type="button"
                                onClick={generatePhoneNo}>
                                Auto-generate
                              </Button>
                            </Box>
                          }
                          placeholder="Enter phone number"
                          value={From.phone}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              phone: e.target.value,
                            });
                          }}
                        />

                        <CustomSelect
                          label="State*"
                          value={From?.state || ""}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              state: e.target.value,
                            });
                          }}
                          options={states
                            .concat(
                              selectedCourier === "usps"
                                ? [
                                    {
                                      Country: "US",
                                      ID: "VI",
                                      Name: "U.S. Virgin Islands",
                                    },
                                    {
                                      Country: "US",
                                      ID: "GU",
                                      Name: "Guam",
                                    },
                                  ]
                                : []
                            )
                            .filter((state) => state.Country === "US")
                            .map((state) => ({
                              label: state.Name,
                              value: state.ID,
                            }))}
                          required
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Section>
              <Grid container mt={2} spacing={2}>
                <Grid item xs={12}>
                  <CustomTable
                    fields={[
                      "Receiver Name ",
                      "Address",
                      "Weight",
                      "Price",
                      "Reference",
                      selectedCourier !== "usps" ? "Dimentions" : "",
                    ]}>
                    {orderDATA?.map((order, index) => (
                      <TableRow key={order.ToName}>
                        <TableCell>{order.ToName}</TableCell>
                        <TableCell>
                          {order.ToStreet1} , {order.ToStreet2} <br />
                          {order.ToCity}, {order.ToState}, {order.ToZip} <br />
                          {order.ToCountry}
                        </TableCell>
                        <TableCell>{order.Weight}</TableCell>
                        <TableCell>${order?.price ?? 0.0}</TableCell>

                        <TableCell>
                          <Field
                            label=""
                            multiline
                            rows={2}
                            placeholder="Enter Reference(optional)"
                            value={orderDATA[index]?.reference1 || ""}
                            onChange={(e) =>
                              setOrderDATA((prevOrderDATA) => {
                                const newData = [...prevOrderDATA];
                                newData[index] = {
                                  ...newData[index],
                                  reference1: e.target.value,
                                };
                                return newData;
                              })
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Stack spacing={1} direction={"row"}>
                            {selectedCourier !== "usps" && (
                              <>
                                <Field
                                  label="Height*"
                                  placeholder="Enter Height"
                                  value={orderDATA[index]?.Height || ""}
                                  onChange={(e) =>
                                    setOrderDATA((prevOrderDATA) => {
                                      const newData = [...prevOrderDATA];
                                      newData[index] = {
                                        ...newData[index],
                                        Height: e.target.value,
                                      };
                                      return newData;
                                    })
                                  }
                                  required
                                />
                                <Field
                                  label="Width*"
                                  placeholder="Enter Width"
                                  value={orderDATA[index]?.Width || ""}
                                  onChange={(e) =>
                                    setOrderDATA((prevOrderDATA) => {
                                      const newData = [...prevOrderDATA];
                                      newData[index] = {
                                        ...newData[index],
                                        Width: e.target.value,
                                      };
                                      return newData;
                                    })
                                  }
                                  required
                                />
                                <Field
                                  label="Length*"
                                  placeholder="Enter Length"
                                  value={orderDATA[index]?.Length || ""}
                                  onChange={(e) =>
                                    setOrderDATA((prevOrderDATA) => {
                                      const newData = [...prevOrderDATA];
                                      newData[index] = {
                                        ...newData[index],
                                        Length: e.target.value,
                                      };
                                      return newData;
                                    })
                                  }
                                  required
                                />
                              </>
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </CustomTable>
                </Grid>
              </Grid>
              <Divider />
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                mt={2}
                sx={{ fontWeight: 500 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    disabled={validateLoader}
                    onClick={getPricing}
                    variant="outlined"
                    color="success"
                    sx={{
                      mr: 2,
                      flex: "none",
                      bgcolor: alpha(theme.palette.success.main, 0.1),
                      fontWeight: 600,
                    }}>
                    {validateLoader ? <Loader /> : "Get Pricing"}
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}>
                  <span style={{ color: "silver" }}>Your Total :</span>
                  <Typography color="primary" fontWeight={600}>
                    {typeof csvPrice === "number" ? (
                      <>${csvPrice?.toFixed(2)}</>
                    ) : (
                      "N/A"
                    )}
                  </Typography>
                  <Button
                    disabled={csvpaydisabled || csvPrice === 0}
                    onClick={createOrderFromCSV}
                    variant="contained"
                    sx={{ ml: 1, px: 3 }}>
                    {loader ? <Loader /> : "Create Order"}
                  </Button>
                </div>
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="end"
                alignItems="center"
                spacing={1}
                mt={2}></Stack>
            </Section>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default ASCsvOrders;
